<template>
    <v-container>
    <v-data-table
    :headers="headers"
    :items="itemsWithIndex"
    class="elevation-2"
  >
  <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-toolbar-title tag class="primary--text">List Point of Interest</v-toolbar-title>
      <v-dialog
        v-model="editDialog"
        max-width="600px"
        persistent
      >
        <v-card>
          <v-card-title>
            <span class="text-h5 primary--text">Edit Point of Interest</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                 <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedPoint.name"
                    label="Name"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedPoint.description"
                    label="Description"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedPoint.longitude"
                    label="Longitude"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedPoint.latitude"
                    label="Latitude"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-file-input
                  :placeholder="editedPoint.picturePath.split('_').pop()"
                    @change="uploadPictureInterest(uploadPic)"
                    v-model="uploadPic"
                    label="Point of Interest Picture"
                  ></v-file-input>
                  <a v-if="editedPoint.picturePath != ''" :href="editedPoint.picturePath" target="_blank">
                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                  </a>
                  <a v-if="editedPoint.picturePath == ''" style="cursor:default;">
                    <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                    <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                  </a> 
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="primary--text"
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              class="primary--text"
              text
              @click="postEditPoint"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" persistent max-width="500px">
        <v-card>
          <v-card-title>Are you sure you want to delete this?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary--text" text @click="close">Cancel</v-btn>
            <v-btn class="primary--text" text @click="postDeletePoint">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
  </template>
  <template v-slot:[`item.no`]="props">
      {{ props.item.index }}
  </template>
  <template v-slot:[`item.actions`]="props">
      <template>
          <v-icon small class="mr-2 green--text" @click="editPointItem(props.item)">mdi-pencil</v-icon>
          <v-icon small text class="red--text" @click="deletePointItem(props.item.id)">mdi-delete</v-icon>
      </template>
  </template>
  </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
        editDialog: false,
        dialogDelete: false,
        delSelected: '',
        headers: [
            { text: 'No.', align: 'start', value:'no' },
            { text: 'Point of Interest Name', value:'name' },
            { text: 'Description', value:'description' },
            { text: 'Actions', value:'actions', sortable: false }
        ],

        pointData:[],
        uploadPic: null,
        editedPoint: {
            name: '',
            description: '',
            longitude: '',
            latitude: '',
            picturePath: ''
        },
        editedIndex: -1,

  }),

  methods: {
    getAllPoint(){
        axios.get(`${process.env.VUE_APP_API_URL}/cms/interest`, {
            headers: {
                Authorization: 'Bearer ' + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            this.pointData = response.data
            console.log(this.pointData)
        })
        .catch((error) => {
            console.log(error)
        })
    },

    postEditPoint() {
      const payload = this.editedPoint
      axios.put(`${process.env.VUE_APP_API_URL}/cms/editinterest`, payload, {
          headers: {
              Authorization: 'Bearer ' + this.$store.getters.user.token,
          },
      })
      .then((response) => {
          this.getAllPoint()
          console.log(response.data)
          this.close()
      })
      .catch((error) => {
          console.log(error)
      })
    },

    postDeletePoint() { 
        axios.get(`${process.env.VUE_APP_API_URL}/cms/delinterest/${this.delSelected}`, {
                headers: {
                    Authorization: 'Bearer ' + this.$store.getters.user.token,
                },
            })
            .then((response) => {
                let temp = this.pointData.findIndex((item) => item.id === this.delSelected)
                this.pointData.splice(temp, 1)
                this.close()
            })
            .catch((error) => {
                console.log(error)
            })
    },

    editPointItem(item) {
        this.editedIndex = this.pointData.indexOf(item)
        this.editedPoint = Object.assign({}, item)
        this.editDialog = true
    },

    deletePointItem(val) {
        this.delSelected = val
        this.dialogDelete = true
    },

    close () {
    this.editDialog = false
    this.dialogDelete = false
    },

    uploadPictureInterest(data){
      let formData = new FormData();

        formData.append("files", data);

        axios.post(`https://temptunnel1.shaz.my/api/cms/upload`, formData, {
            headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            console.log(response);
            // this.stationData = response.data.filepath
            this.pointData.picturePath = response.data.filepath;
            console.log(this.pointData.picturePath);
        })
        .catch((error) => {
            console.log(error);
        });
    }

  },

  computed: {
    itemsWithIndex() {
       return this.pointData.map(
           (pointData, index) => ({
           ...pointData,
           index: index + 1
         }))
    }
  },

  mounted() {
      this.getAllPoint()
  }
}
</script>

<style lang="scss" scoped>

</style>
